import React from "react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";

export default function Welcome() {
  return (
    <section className="about spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="about__video set-bg">
              <img src={logo} alt="Logo" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="about__text">
              <div className="section-title">
                <span>Qui sommes-nous ?</span>
                <h2>Bienvenue chez Marraine Esthétiques</h2>
              </div>
              <p>
                Votre partenaire de confiance pour la santé et le bien-être à
                l'étranger, offrant des services de tourisme médical
                personnalisés avec des prestataires de soins de santé de premier
                plan dans la Tunisie. Nous garantissons un parcours fluide, sûr
                et soutenant, de la consultation à la récupération.
              </p>
              <Link to="/contact" className="primary-btn normal-btn">
                Contactez-nous
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
